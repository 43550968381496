import React from 'react'
import Logo from './Logo';
import Twitter from './Twitter';
import Instagram from './Instagram';
import Linkedin from './LinkedIn';
import Inst from '../../assets/logo/instagram.svg'


export default function Navbar({color}) {
    console.log(color);
    return (
        <div>
            <nav className="navbar regular">
                <Logo color={color}/>
                <div className="center">
                    <div className="socialMedia">
                    <div className="socialMedia-card">
                        <a href="https://instagram.com/anusree_saji/" target="_blank" rel="noopener noreferrer">
                            <div className="social-link-div">
                                <div className="socialMedia-img-div">
                                    <Instagram color={color}/>
                                </div>
                                <span className={'social-1 '+color}></span>
                            </div>
                        </a>
                    </div>
                    <div className="socialMedia-card">
                        <a href="https://twitter.com/anusree_saji" target="_blank" rel="noopener noreferrer">
                            <div className="social-link-div">
                                <div className="socialMedia-img-div">
                                    <Twitter color={color} />
                                </div>
                                <span className={'social-2 '+ color}></span>
                            </div>
                        </a>
                    </div>
                    <div className="socialMedia-card">
                        <a href="https://www.linkedin.com/in/anusreesaji/" target="_blank" rel="noopener noreferrer">
                            <div className="social-link-div">
                                <div className="socialMedia-img-div">
                                    <Linkedin color={color} />
                                </div>
                                <span className={'social-3 '+color}></span>
                            </div>
                        </a>
                    </div>
                </div>
                </div>
            </nav>
        </div>
    )
}
