import React, { useState } from 'react'
import {motion} from 'framer-motion'
import './style.css';

const svgVariants = {
    hidden :{opacity:0},
    visible:{
        opacity:1,
        transition:{duration:1}
    }
}
const pathVariants={
    hidden:{
        opacity:0,
        pathLength:0
    },
    visible:{
        opacity:1,
        pathLength:1,
        transition:{
            duration:2,
            ease:"easeInOut"
        }
    }
}

export default function Logo({color}) {
    return (
        <a href="./">
            <motion.div className="logo"
        variants={svgVariants}
        initial = "hidden"
        animate = "visible"
        >
             <svg viewBox="88.034 146.153 292.308 305.129">
            <motion.path 
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="M 243.347 154.19 L 357.22 218.674 C 362.887 221.884 366.378 227.815 366.378 234.233 L 366.378 363.203 C 366.378 369.621 362.887 375.552 357.22 378.761 L 243.347 443.246 C 237.679 446.455 230.697 446.455 225.03 443.246 L 111.157 378.761 C 105.49 375.552 101.998 369.621 101.998 363.203 L 101.998 234.233 C 101.998 227.815 105.49 221.884 111.157 218.674 L 225.03 154.19 C 230.697 150.98 237.679 150.98 243.347 154.19 Z" stroke={color} strokeWidth="2" fill="none" />
            <text transform="matrix(1.715688, 0, 0, 1.534846, -148.582901, -13.903186)"  style={{fontFamily:'Abril Fatface', fontSize: '150px', whiteSpace: 'pre',}} fill={color} x="171.795" y="245.869">A</text>
             <text style={{whiteSpace: 'pre', fontFamily:'Roboto Slab', fontSize: '23.9px'}} x="294.872" y="186.895"> </text>
            </svg>
        </motion.div>
        </a>
        
    )
}
